<template>
  <div>
    <profile-header text="My Friends" :tabs="tabs"/>

    <v-row>
      <v-col cols="5">
        <v-autocomplete 
          v-model="search" 
          :items="friends"
          clearable
          item-text="name"
          prepend-icon="fa-search" 
          label="Search"
          @click:clear="search = null"
        />

        <div v-if="selected" class="search-result">
          <div class="avatar">
            <v-icon v-if="!selected.avatar" size="100">
              far fa-user-circle
            </v-icon> 
          </div>

          <div class="friend-info">
            <h1>{{selected.name}}</h1>
            <h4>New York, NY</h4>
          </div>

          <div class="actions">
            <div v-if="selected.owner" class="owner">
              <icon-button icon="fas fa-user-plus" label="Remove Friend" />
              <icon-button icon="fas fa-user-plus" label="Message" />
              <icon-button icon="fas fa-user-plus" label="Invite to Pod" />
              <icon-button icon="fas fa-user-plus" label="Report" />
            </div>

            <icon-button v-else icon="fas fa-user-plus" label="Add Friend" />
          </div>
            
        </div>
      </v-col>

      <v-col cols="7">
        <div class="friend-list">
          <div 
            class="friend" 
            :key="`friend-${i}`" 
            v-for="friend, i in filteredFriends"
            @click="() => select(friend)"
          >
            <div class="avatar">
              <v-icon v-if="!friend.avatar" size="40">
                far fa-user-circle
              </v-icon>
            </div>

            <div class="name">
              {{friend.name}}
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
  
</template>

<script>
import IconButton from '../ui/IconButton.vue';
import ProfileHeader from './ProfileHeader.vue'
const words = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus, culpa corrupti iure nihil earum voluptatibus, qui amet fugit laudantium tenetur minima nisi impedit consequatur id possimus delectus velit facere rerum dignissimos illum quasi eius doloribus quod enim. Harum cupiditate officia suscipit rerum corrupti at eligendi nostrum earum. Quam, facere. Nam.";

export default {
  components: { ProfileHeader, IconButton }, 
  data() {
 
    return { 
      search: "",
      selected: null,
      friends: words
        .replace('.', '')
        .split(' ')
        .filter(name => name.length < 8)
        .map(name => ({ name, owner: Math.random() > 0.5 }))
    };
  },
  methods: {
    select(friend) {
      this.selected = friend;
    }
  },
  computed: {
    filteredFriends() {
      if (!this.search || this.search.length === 0) {
        return this.friends;
      }

      return this.friends.filter(f => f.name.startsWith(this.search));
    },
    tabs() {
      return ['All Friends', 'Friend Requests']
    }
  }
}
</script>

<style lang="scss" scoped>
.friend-list {
  display: grid;

  grid-template-columns: repeat(9, 1fr);
  grid-auto-rows: 100px;
}

.friend {
  display: flex;
  flex-flow: column nowrap;

  justify-content: space-around;
  align-items: center;
}

.search-result {
  background-color: #eee;

  display: flex;
  flex-flow: column nowrap;

  justify-content: center;
  align-items: center;
  height: 350px;

  .friend-info {
    padding: 0.5rem;
  }

  .actions {
    margin-top: 1rem;
  }

  .actions, .owner {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: center;
  }
}
</style>