<template>
  <div v-ripple class="icon-button" @click="$emit('click')">
    <v-icon>{{icon}}</v-icon>
    <div class="label">{{label}}</div>
  </div>
</template>

<script>
export default {
  props: {
    icon: { type: String, requried: true },
    label: { type: String, required: true }
  }
}
</script>

<style lang="scss" scoped>
.icon-button {
  display: flex;
  flex-flow: column nowrap;

  justify-content: center;
  align-items: center;

  padding: 0.3rem;

  transition: background-color 0.3s;

  border-radius: 1rem;

  &:hover {
    background-color: #ddd;
  }
}

.label {
  padding: 1rem;
}
</style>